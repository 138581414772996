import React, { useEffect, useState } from 'react';

const App = () => {
  const [subdomainData, setSubdomainData] = useState(null);

  useEffect(() => {
    // Step 1: Get the subdomain from the URL
    const getSubdomain = () => {
      const hostname = window.location.hostname;
      const parts = hostname.split('.');
      if (parts.length > 2) {
        return parts[0];
      }
      return null;
    };

    const subdomain = getSubdomain();

    // Step 2: Load the JSON data (mocked here for simplicity)
    const fetchData = async () => {
      try {
        // Assuming you have a JSON file at public/data.json
        const response = await fetch('/data.json');
        const data = await response.json();

        // Step 3: Extract the variables associated with the subdomain
        if (subdomain && data[subdomain]) {
          setSubdomainData(data[subdomain]);
          document.title = data[subdomain].name;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (subdomainData) {
      const script = document.createElement('script');
      script.src = 'https://cdn.pulse.is/livechat/loader.js';
      script.setAttribute('data-live-chat-id', subdomainData.code);
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [subdomainData]);

  return (
    <div>
      {subdomainData ? (
        <div className="App">
          <h1>{subdomainData.name}</h1>
          <img src={subdomainData.logo} alt={`${subdomainData.name} logo`} />
          <script 
            src="https://cdn.pulse.is/livechat/loader.js" 
            data-live-chat-id={subdomainData.code} 
            async
          ></script>
        </div>
      ) : (
        <div>
          <h1>Atria</h1>
          <p>AI que trabaja para tu empresa</p>
          <p><a href="https://www.tryatria.ai" title="Atria">www.tryatria.ai</a></p>
        </div>
      )}
    </div>
  );
};

export default App; // Ensure App is the default export